import React from "react";
import Female from "../../../assets/pricing/female.png";
import { Link } from "@remix-run/react";
import styles from "./pricing.module.scss";

export default function Cta() {
  return (
    <div
      className="row align-items-center mx-auto"
      style={{
        width: "90%",
      }}
    >
      <div className="col-md-6 text-start">
        <h2>
          Need Something More? <br /> Let’s Talk.
        </h2>
        <p className="py-2">
          If your project requires more than what’s offered in our standard
          plans, we’ve got you covered. Whether you need custom solutions,
          enterprise-level support, or have specific security requirements,
          we’re here to help.
        </p>
        <Link to="mailto:hi@headlockr.io" className="btn btn-tertiary">
          Contact Us
        </Link>
      </div>
      <div className={`${styles.girl} col-md-6 d-flex justify-content-center`}>
        <div
          style={{
            width: "300px",
            height: "300px",
            boxShadow: "5px -5px 0px #2E3A8B",
          }}
          className="position-relative bg-tertiary rounded-circle"
        >
          <img
            style={{
              clipPath: "circle()",
            }}
            alt="female"
            src={Female}
            className="img-fluid"
          />
        </div>
      </div>
    </div>
  );
}
