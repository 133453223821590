import { useNavigate } from "@remix-run/react";
import React, { useState } from "react";

export default function Plan({ plans }) {
  const [activeTab, setActiveTab] = useState(0); // Active tab for mobile view

  return (
    <div className="row mt-2 justify-content-center">
      <div className="d-md-none">
        <ul
          className="nav nav-pills  justify-content-around mb-3"
          id="mobileTabs"
        >
          {plans.map((plan, index) => (
            <li className="nav-item" key={index}>
              <a
                role="button"
                className={`nav-link bg-secondary ${
                  activeTab === index ? "active bg-tertiary" : ""
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => setActiveTab(index)}
              >
                {plan.name}
              </a>
            </li>
          ))}
        </ul>

        <div className="tab-content">
          {plans.map((plan, index) => (
            <div
              className={`tab-pane fade ${
                activeTab === index ? "show active" : ""
              }`}
              key={index}
            >
              <div
                className={`text-start p-2 rounded-4 ${
                  plan.mostPopular ? "bg-tertiary" : "bg-secondary"
                }`}
                style={{ position: "relative" }}
              >
                {plan.mostPopular && (
                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      backgroundColor: "#ffea00",
                      padding: "5px 10px",
                      borderRadius: "10px",
                      fontSize: "12px",
                    }}
                  >
                    Most Popular
                  </div>
                )}
                <h3>{plan.name}</h3>
                <p>{plan.description}</p>
                <h3 className="mb-4">{plan.price} / Month</h3>
                <a
                  href={`/checkout/${plan.id}`}
                  className={`btn ${
                    plan.mostPopular ? "btn-secondary" : "btn-tertiary"
                  } w-100 mb-4`}
                >
                  Get Started Now
                </a>
                <ul className="list-unstyled">
                  {plan.features.map((feature, featureIndex) => (
                    <li
                      key={featureIndex}
                      className={`mb-2 d-flex align-items-center ${
                        feature.included ? "text-success" : "text-danger"
                      }`}
                    >
                      <span
                        style={{
                          width: "30px",
                          height: "30px",
                          fontSize: "10px",
                        }}
                        className="bg-white p-1 rounded-circle align-items-center d-flex justify-content-center"
                      >
                        {feature.included ? "✔️" : "❌"}
                      </span>
                      <span className="ms-2">{feature.text}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="d-none d-md-flex justify-content-center">
        {plans.map((plan, index) => (
          <div
            key={index}
            className={`text-start p-2 rounded-4 col-md-3 m-1 ${
              plan.mostPopular ? "bg-tertiary" : "bg-secondary"
            }`}
            style={{
              minWidth: "31%",
              maxWidth: "300px",
              position: "relative",
            }}
          >
            {plan.mostPopular && (
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  backgroundColor: "#ffea00",
                  padding: "5px 10px",
                  borderRadius: "10px",
                  fontSize: "12px",
                }}
              >
                Most Popular
              </div>
            )}
            <h3>{plan.name}</h3>
            <p>{plan.description}</p>
            <h4 className="mb-3 mt-1">{plan.price} / Month</h4>

            <a
              href={`/checkout/${plan.id}`}
              className={`btn ${
                plan.mostPopular ? "btn-secondary" : "btn-tertiary"
              } w-100 mb-4`}
            >
              Get Started Now
            </a>

            <ul className="list-unstyled">
              {plan.features.map((feature, featureIndex) => (
                <li
                  key={featureIndex}
                  className={`mb-2 d-flex align-items-center ${
                    feature.included ? "text-success" : "text-danger"
                  }`}
                >
                  <span
                    style={{
                      width: "30px",
                      height: "30px",
                      fontSize: "10px",
                    }}
                    className="bg-white p-1 rounded-circle align-items-center d-flex justify-content-center"
                  >
                    {feature.included ? "✔️" : "❌"}
                  </span>
                  <p className="ms-2">{feature.text}</p>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}
