import React from "react";
import Plan from "./Plans";
import Male from "../../../assets/pricing/male.png";
import MessageBubble from "../../../components/utils/MessageBubble";
import Cta from "./Cta";
import styles from "./pricing.module.scss";
import { plans } from "../../../helpers/plans";

export default function Pricing() {
  return (
    <div className="text-center mt-5">
      <h2>
        Powerful features for <br />
        <span className="text-tertiary"> powerful creators</span>
      </h2>
      <p>Choose a plan that’s right for you</p>
      <Plan plans={plans} />
      <div className="mt-8 position-relative">
        <img
          src={Male}
          alt="male"
          style={{
            width: "200px",
            height: "auto",
            position: "absolute",
            left: "55%",
            bottom: "40px",
            transform: "translate(-50%, 50%)",
          }}
        />
        <div
          style={{
            left: "44%",
            bottom: "-25px",
          }}
          className={`position-absolute translate-middle ${styles.bubble}`}
        >
          <MessageBubble message="14-day money back! 🤯" />
        </div>
      </div>
      <div className="my-20">
        <Cta />
      </div>
    </div>
  );
}
